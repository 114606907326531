import React from 'react';
import CssBaseline from '@mui/material/CssBaseline'
import RootPage from "./components/RootPage/RootPage"
import store from "./store"
import { Provider } from "react-redux"
import useMediaQuery from '@mui/material/useMediaQuery';

import { createTheme, ThemeProvider } from "@mui/material/styles"

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const customTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: { main: "#3858e9" }
        },
        typography: {
          fontFamily: [
            'Raleway',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
          ].join(','),
        }
      }),
    [prefersDarkMode],
  );
  return (
        <Provider store={store}>
            <ThemeProvider theme={customTheme}>
              <CssBaseline />
              <div className="App">
                <RootPage />
              </div>
            </ThemeProvider>
        </Provider>
  )
}

export default App;
