import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Cases } from "../../enums/entities/Cases"
import { Airport } from "../../enums/entities/Airport"
import { Pax } from "../../enums/entities/Pax"

interface CaseState {
    row: Cases | null,
    newRow: Cases | null,
}

const initialState: CaseState = {
    row: null,
    newRow: null,
}

export const caseSlice = createSlice({
    name: 'case',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setRow: (state, action: PayloadAction<Cases>) => {
            state.row = action.payload
        },
        setNewRow: (state, action: PayloadAction<Cases>) => {
            state.newRow = action.payload
        },
        setNewRowField: (state, action: PayloadAction<{ fieldToChange: string, value: any }>) => {
            if (state.newRow !== null)
                // @ts-ignore
                state.newRow[action.payload.fieldToChange] = action.payload.value
        },
        setNewStopOverAirport: (state, action: PayloadAction<{ index: number, value: Airport }>) => {
            if (state.newRow !== null)
                // @ts-ignore
                state.newRow.stopOverAirports[action.payload.index] = action.payload.value
        },
        setNewPax: (state, action: PayloadAction<{ index: number, fieldToChange: string, value: any }>) => {
            if (state.newRow !== null)
                // @ts-ignore
                state.newRow.passengers[action.payload.index][action.payload.fieldToChange] = action.payload.value
        },
        setNewBookingRef: (state, action: PayloadAction<{ index: number, value: string }>) => {
            if (state.newRow !== null)
                // @ts-ignore
                state.newRow.bookingRef[action.payload.index] = action.payload.value
        },
    }
})

export const { setRow,
               setNewRow,
               setNewRowField,
               setNewStopOverAirport,
               setNewPax,
               setNewBookingRef 
             } = caseSlice.actions

export default caseSlice.reducer
