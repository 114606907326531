import axios from "axios"
import { Ok, Err, Result } from "ts-results"

import { Cases } from "./enums/entities/Cases"
import { Status } from "./enums/caseStatus"
import { CaseAutomationLevel } from "./enums/caseAutomation"
import { FlightStatsData } from "./components/pages/CaseViewPage/FlightStatsView/FlightStatsData"
import { ActionKey } from "./enums/ActionKey"

const ROOT_URL = process.env.REACT_APP_ROOT_URL

export const sendLoginRequest = async (username: string, password: string): Promise<Result<void, Error>> => {
    const data = {userid: username, password}
    const reqUrl = `${ROOT_URL}/intern/login?username=${username}&password=${password}`
    try {
        const res = await axios.post(reqUrl, data, {
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })

        if (res.status === 200) return Ok.EMPTY
        return Err(new Error(`Respond returned status ${res.status}`))
    } catch (e: any) {
        console.error(e)
        return Err(e)
    }
}

type RestRes = { id: string, deadlineType: string, deadlineDate: string, courtCaseId: string, flightNumber: string, flightArrivalDate: string, row: Cases }
export const getCassesApi = async (): Promise<Result<Array<Cases & RestRes>, Error>> => {
    try {
        const data = await axios.get(`${ROOT_URL}/intern/cases`, {
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })

        let res = data.data.data
        res = res.map((row: Cases) => { return {
            ...row,
            id: row.caseId,
            deadlineType: "Bob",
            deadlineDate: "12/08/2022",
            courtCaseId: "",
            flightNumber: `${row.specificFlightNumPrefix}${row.specificFlightNum}`,
            flightArrivalDate: row.specificFlightDate,
            action: row,
        }})

        return Ok(res)
    } catch (e: any) {
        console.error(e)
        return Err(e)
    }
}

export const getCase = async (caseId: number): Promise<Result<Required<Cases>, Error>> => {
    try {
        const res = await axios.post(`${ROOT_URL}/intern/case?caseId=${caseId}`, {}, {
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })

        return Ok(res.data)
    } catch (e: any) {
        console.error(e)
        return Err(e)
    }
}

// FIXME: fix type later
export const getCaseDataSmall = async (): Promise<Result<any, Error>> => {
    try {
        const res = await axios.get(`${ROOT_URL}/intern/cases-small-data`, {
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })

        return Ok(res.data)
    } catch(e: any) {
        console.error(e)
        return Err(e)
    }
}

// Function for getting signed read url for a file
export const getSignedReadUrlApi = async (fileId: string): Promise<Result<string, Error>> => {
    try {
        const res = await axios.get(`${ROOT_URL}/intern/get-case-file-url?fileName=${fileId}`, {
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })
        return Ok(res.data.url)
    } catch(e: any) {
        console.error(e)
        return Err(e)
    }
}

// FIXME: Should this be here?
export interface FileReq {
  name: string
  id: string
}

// FIXME: Should this be here?
export interface FileGroup {
  name: string
  files: Array<FileReq>
}

// FIXME: Should this be here?
export type Files = Array<FileGroup>
export const getFilesApi = async (caseId: number): Promise<Result<Files, Error>> => {
    try {
        const res = await axios.get(`${ROOT_URL}/intern/case-files?caseId=${caseId}`, {
            withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
            })
        return Ok(res.data.files)
    } catch(e: any) {
        console.error(e)
        return Err(e)
    }
}

export const changeStatusApi = async (caseId: number, status: Status) : Promise<Result<void, Error>> => {
    try {
        const res = await axios.post(`${ROOT_URL}/intern/change-case-status?caseId=${caseId}&newStatus=${status}`, {},{
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })

        if (res.data.success) return Ok.EMPTY

        return Err(new Error("An error occured on the server"))
    } catch(e: any) {
        console.error(e)
        return Err(e)
    }
}


export const changeAutomationLevelApi = 
    async (caseId: number, automationLevel: CaseAutomationLevel) : Promise<Result<void, Error>> => {
    try {
        const res = await axios.post(`${ROOT_URL}/intern/change-case-automation-level?caseId=${caseId}&newAutomationLevel=${automationLevel}`, {},{
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })

        if (res.data.success) return Ok.EMPTY

        return Err(new Error("An error occured on the server"))
    } catch (e: any) {
        console.error(e)
        return Err(e)
    }
}

export const replaceCase = async (caseData: Cases): Promise<Result<void, Error>> => {
    try {
        const res = await axios.post(`${ROOT_URL}/intern/case-change`,
                                    caseData,
                                    { withCredentials: true, headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'} })

        if (res.data.success) return Ok.EMPTY

        return Err(new Error("Error with saving case"))
    } catch (e: any) {
        console.error(e)
        return Err(e)
    }
}

export const addDeadline = async (caseId: number, date: Date, deadline: string): Promise<Result<number, Error>> => {
    try {
        const res = await axios.post(`${ROOT_URL}/intern/deadline`,
                                    { caseId, date, deadline },
                                    { withCredentials: true, headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'} })

        return Ok(res.data.deadlineId)
    } catch (e: any) {
        console.error(e)
        return Err(e)
    }
}

export const getDeadlines = async (caseId: number): Promise<Result<Array<{ id: number, caseId: number, date: Date, deadline: string }>, Error>> => {
    try {
        const res = await axios.get(`${ROOT_URL}/intern/get-deadlines?caseId=${caseId}`,
                                    { withCredentials: true, headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'} })

        const ret = res.data.deadlines.map((d: any) => { return { ...d, date: new Date(d.date) } }) // FIXME: Why is it not Date type from database?
        return Ok(ret)
    } catch (e: any) {
        console.error(e)
        return Err(e)
    }
}

export const deleteDeadline = async (deadlineId: number): Promise<Result<void, Error>> => {
    try {
        const res = await axios.post(`${ROOT_URL}/intern/delete-deadline?deadlineId=${deadlineId}`,
                                    {},
                                    { withCredentials: true, headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'} })

        return Ok.EMPTY
    } catch (e: any) {
        console.error(e)
        return Err(e)
    }
}

// FIXME: Remove
export const getLogsApi = async (caseId: number) : Promise<Result<any, Error>> => {
    try {
        const res = await axios.get(`${ROOT_URL}/intern/case-status-changes?caseId=${caseId}`,{
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })

        if (res.data.success) return res.data.data

        return Err(new Error("An error occured on the server"))
    } catch(e: any) {
        console.error(e)
        return Err(e)
    }
}

// export const searchAirports = async (query: string): Promise<Result<Array<AirportResponse>, Error>> => {
export const searchAirports = async (query: string): Promise<Result<Array<any>, Error>> => {
    // @ts-ignore
    if (query.length < 3 || query.length === 0 || query === 0) {
        return Ok([])
    }
    const data = { searchText: query }
    try {
        const res = await axios.post(ROOT_URL + "/search-airports", data)
        return Ok(res.data)
    } catch (err: any) {
        console.error(err)
        return Err(err)
    }
}

export const searchAirlines = async (query: string): Promise<Result<Array<{ name: string, iata: string, ec261: boolean, id: string }>, Error>> => {
    // @ts-ignore
    if (query.length < 3 || query.length === 0 || query === 0) {
        return Ok([])
    }
    const data = { searchText: query }
    try {
        const res = await axios.post(ROOT_URL + "/search-airlines", data)
        return Ok(res.data)
    } catch (err: any) {
        console.error(err)
        return Err(err)
    }
}

export const getLogs = async (caseId: number): Promise<Result<Array<any>, Error>> => {
    try {
        const res = await axios.get(`${ROOT_URL}/intern/get-logs?caseId=${caseId}`, {
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })
        return Ok(res.data)
    } catch (e: any) {
        console.error(e)
        return Err(e)
    }
}

export const getSignedTemplateReadUrlApi = async (templateId: string): Promise<Result<string, Error>> => {
    try {
        const res = await axios.get(`${ROOT_URL}/intern/template-read-url?docType=${templateId}`, {
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })
        return Ok(res.data.url)
    } catch(e: any) {
        return Err(e)
    }
}

export const uploadTemplateFile = async (file: File, templateId: string): Promise<Result<void, Error>> => {
    try {
        const formData = new FormData()
        formData.append('template', file)
        const res = await axios.post(`${ROOT_URL}/intern/upload-template?docType=${templateId}`, formData, {
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data'}
        })

        if (res.data.success) return Ok.EMPTY

        return Err(new Error("An error occured on the server"))
    } catch(e: any) {
        return Err(e)
    }
}

export const getFlightStatsData = async (id: string): Promise<Result<FlightStatsData, Error>> => {
    try {
        const res = await axios.get(`${ROOT_URL}/intern/flight-info?id=${id}`, {
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })
        return Ok(res.data)
    } catch(e: any) {
        console.error(e)
        return Err(e)
    }
}

export const runAction = async (caseId: number, actionKey: ActionKey, payload: null | any = null): Promise<Result<void, Error>> => {
    try {
        const res = await axios.post(`${ROOT_URL}/intern/run-action?caseId=${caseId}&actionKey=${actionKey}`, {payload: payload}, {
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'},
        })

        if (res.data.success) return Ok.EMPTY

        return Err(new Error("An error occured on the server"))
    } catch(e: any) {
        return Err(e)
    }
}

export const getActionParameters = async (caseId: number, actionKey: ActionKey): Promise<Result<any, Error>> => {
    try {
        const res = await axios.get(`${ROOT_URL}/intern/action-parameters?caseId=${caseId}&actionKey=${actionKey}`, {
            withCredentials: true,
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })
        return Ok(res.data)
    } catch(e: any) {
        return Err(e)
    }
}
