import React from "react"
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import StartPage from "../pages/StartPage/StartPage";
import ExcelPage from "../pages/ExcelPage/ExcelPage";


import TemplatesPage from "../pages/TemplatesPage/TemplatesPage"
import LoginPage from "../pages/LoginPage/LoginPage";
// import { <++> } from "@mui/material"
// import { <++> } from "./RootSlice"
// import Footer from '../basic/Footer';
// import Header from '../basic/Header';
// import Frontpage from '../pages/FrontPage/Frontpage';
// import CompositionPage from "../pages/CompositionPage/CompositionPage";
//
// import { ReasonType } from "../pages/FrontPage/ReasonSlice"


function RootPage(props: {}) {
  return <React.Fragment>
           <BrowserRouter>
             <Routes>
               <Route path="/" element={<StartPage/>} />
               <Route path="/excel" element={<ExcelPage/>} />
               <Route path="/login" element={<LoginPage/>} />
               <Route path="/templates" element={<TemplatesPage/>} />
             </Routes>
           </BrowserRouter>
         </React.Fragment>
}

export default RootPage
