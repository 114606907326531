export enum DocumentTypes {
    POA = "POWER_OF_ATTORNEY",
    AIRLINE_EMAIL = "AIRLINE_EMAIL",
    VALIDATION_REPORT = "VALIDATION_REPORT",
    BOARDING_REJECTION_SUBPOENA = "BOARDING_REJECTION_SUBPOENA",
    CANCELLATION_SUBPOENA = "CANCELLATION_SUBPOENA",
    DELAYED_LAST_OR_DIRECT_SUBPOENA = "DELAYED_LAST_OR_DIRECT_SUBPOENA",
    MISSED_LAYOVER_SUBPOENA = "MISSED_LAYOVER_SUBPOENA",
    REFUND_SUBPOENA = "REFUND_SUBPOENA",
    ALT_ROUTES_APPENDIX = "ALT_ROUTES_APPENDIX",
    TRANSFER_AGREEMENT = "TRANSFER_AGREEMENT"
}