import React from 'react';
import { Grid, TextField } from '@mui/material';
import { ActionInputComponent } from './ActionInputesMap';

const ComputeAlternativesDataInput: ActionInputComponent  = (props:{state: any, setState: (a:any) => void}) => {
    return (
        <div>
        <Grid container spacing={2}>
            <Grid item xs={12}>
            <TextField
                label="Departure IATA"
                defaultValue={"JFK"}
                type="text"
                variant="outlined"
                value={props.state.departureIata}
                onChange={(e) => props.setState({...props.state, departureIata: e.target.value})}
            />
            <TextField
                label="Min Departure Date"
                type="date"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                value={props.state.minDepartureDate}
                onChange={(e) => props.setState({...props.state, minDepartureDate: e.target.value})}
            />
            {/* Add min departure time also */}
            <TextField
                label="Min Departure Time"
                type="time"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                value={props.state.minDepartureTime}
                onChange={(e) => props.setState({...props.state, minDepartureTime: e.target.value})}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                label="Arrival IATA"
                type="text"
                variant="outlined"
                defaultValue={"LAX"}
                value={props.state.arrivalIata}
                onChange={(e) => props.setState({...props.state, arrivalIata: e.target.value})}
            />
            <TextField
                label="Max Arrival Date"
                type="date"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                value={props.state.maxArrivalDate}
                onChange={(e) => props.setState({...props.state, maxArrivalDate: e.target.value})}
            />
            {/* Add min departure time also */}
            <TextField
                label="Max Arrival Time"
                type="time"
                InputLabelProps={{
                    shrink: true,
                }}
                value={props.state.maxArrivalTime}
                onChange={(e) => 
                    {
                        console.log(e.target.value)
                        props.setState({...props.state, maxArrivalTime: e.target.value})
                    }
                }
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                label="Number of alternative routes to show"
                type="number"
                defaultValue={3}
                variant="outlined"
                value={props.state.numOfAlternatives}
                onChange={(e) => props.setState({...props.state, numOfAlternatives: e.target.value})}
            />
            <TextField
                label="Maximum credits used to compute alternatives"
                type="number"
                defaultValue={10}
                variant="outlined"
                value={props.state.maxCredits}
                onChange={(e) => props.setState({...props.state, maxCredits: e.target.value})}
            />
            </Grid>

    </Grid>
        </div>
    )
}

export default ComputeAlternativesDataInput;