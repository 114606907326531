import {useNavigate} from "react-router-dom"
import { sendLoginRequest } from "../../../requests"
import LoadingButton from '@mui/lab/LoadingButton'
import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { FormHelperText, Button } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

const Copyright = (props: any) =>
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://flysag.dk/">
        FlySag
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>

const handleGoogleLogin = () => {
  window.open("https://api.flysag.dk/intern/auth/google", "_self")
}

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme()

const LoginPage = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const customTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: { main: "#3858e9" }
        },
        typography: {
          fontFamily: [
            'Raleway',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
          ].join(','),
        }
      }),
    [prefersDarkMode],
  )

  const ERROR_MESSAGE = "Failed to sign in :/"
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const navigate = useNavigate()
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const username = data.get('email') as string
    const password = data.get('password') as string
    setLoading(true)
    const didLogin = await sendLoginRequest(username, password)
    if (didLogin.ok){
        navigate("/excel/")
        setLoading(false)
    } else {
        setError(true)
        setLoading(false)
    }
  }

  return (
    <ThemeProvider theme={customTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Username"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            { error ? <FormHelperText error={error}>Failed to sign in :/</FormHelperText> : null}
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </LoadingButton>
          </Box>
          <Button 
            variant="contained"
            fullWidth
            onClick={handleGoogleLogin}
          >
              Login with google
          </Button>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}

export default LoginPage
