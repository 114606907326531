export enum Status {
    Unknown = "Ukendt",
    NotFinished = "Ikke færdiggjort",
    MissingInfo = "Manglende info/docs",
    Received = "Modtaget info",
    InitialPaxEmailSent = "Første pax email sendt",
    PulledDataFromBooking = "Data trukket fra booking bekræftelse",
    CaseDecoratedWithData = "Sag dekoreret med data",
    TransferAgreementGenerated = "Overførselsaftale genereret",
    Validated = "Valideret",
    WaitingForAirlineResp = "Venter på svar fra flyselskab",
    ClaimAccepted = "Krav accepteres",
    ClaimRejected = "Krav afvist",
    GoodDocs = "God dokumentation",
    BadDocs = "Ingen/dårlig dokumentation",
    CityCourtCase = "Byretssag",
    CityCourtJudge = "Byretsdom",
    BailIfCase = "Fogedretssag",
    BailIfJudge = "Fogedretsdom",
    ProcAuthBoardCaseWon = "Procesbevillingsnævnssag vundet",
    ProcAuthBoardCaseLost = "Procesbevillingsnævnssag tabt",
    CourtCase = "Landsretssag",
    CourtJudge = "Landsretsdom",
    CaseWon = "Sag vundet",
    CaseLost = "Sag tabt",
    PaymentDone = "Betaling sendt",
}
