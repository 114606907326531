import React, { useState } from "react"
import { Table,
         TableBody,
         TableRow,
         TableCell,
         TableContainer,
         TextField,
         Select,
         MenuItem,
         FormControl,
         Autocomplete,
         Button,
       } from "@mui/material"
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { da } from "date-fns/locale"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { IRow } from "../../../utils"
import { Cases } from "../../../enums/entities/Cases"
import ReasonType from "../../../enums/ReasonType"
import CompensationInputChoice from "../../../enums/CompensationInputChoice"
import DelayMsgAnswer from "../../../enums/DelayMsgAnswer"
import NoRebookingChoice from "../../../enums/NoRebookingChoice"
import { Answer } from "../../../enums/utils"
import { searchAirports, searchAirlines } from "../../../requests"
import { InfoTableBody } from "../../../infoTableUtil"

interface InfoTableProps {
    onChange: (item: any) => (e: any) => void
    wrongedDepAirportChange: (e: any, value: any) => void
    wrongedArrAirportChange: (e: any, value: any) => void
    specificFlightAirlineChange: (e: any, value: any) => void
    newFlightAirlineChange: (e: any, value: any) => void
    endFlightAirlineChange: (e: any, value: any) => void

    dateOnChange: (item: any) => (date: Date | null) => void
    openFlightStatsDialog: (id: any) => void
}

interface AirportAutocompleteOption {
    name: string,
    iata: string,
    country: string,
    city: string,
    countryCode: string,
    ec261: boolean
}

type HandleAirportAutcomFunc = (d: Array<AirportAutocompleteOption>) => void
type HandleAirlineAutcomFunc = (d: Array<any>) => void
const InfoTable = (props: InfoTableProps) => {
  const [wrongedDepAirportAutocomp, setWrongedDepAirportAutocomp] = 
    useState<Array<AirportAutocompleteOption>>([])

  const [wrongedArrAirportAutocomp, setWrongedArrAirportAutocomp] = 
    useState<Array<AirportAutocompleteOption>>([])

  const [specificFlightAirlineAutocomp, setSpecificFlightAirlineAutocomp] = 
    useState<Array<any>>([])
  const [newFlightAirlineAutocomp, setNewFlightAirlineAutocomp] = 
    useState<Array<any>>([])
  const [endFlightAirlineAutocomp, setEndFlightAirlineAutocomp] = 
    useState<Array<any>>([])

  const handleAutocompAirportsReq = (f: HandleAirportAutcomFunc) => async (e: object) => {
      // @ts-ignore
      const data = await searchAirports(e.target.value)
      if (data.ok)
          f(data.val)
  }

  const handleAutocompAirlineReq = (f: HandleAirlineAutcomFunc) => async (e: object) => {
      // @ts-ignore
      const data = await searchAirlines(e.target.value)
      if (data.ok)
        f(data.val)
  }

  return <div style={{overflow: "hidden"}}>
           <TableContainer sx={{maxHeight: 440}}>
             <InfoTableBody />
           </TableContainer>
         </div>
  // return <div style={{overflow: "hidden"}}>
            // <TableContainer sx={{maxHeight: 440}}>
               // <Table>
                 // <TableBody>
                   // <TableRow>
                     // <TableCell>
                         // Årsag
                     // </TableCell>
                     // <TableCell>
                         // <FormControl size="small">
                             // <Select
                                // value={props.newRow.reason}
                                // onChange={props.onChange("reason")}
                             // >
                             // {
                                // Object.values(ReasonType).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
                             // }
                             // </Select>
                         // </FormControl>
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                        // Afgangs lufthavn
                     // </TableCell>
                     // <TableCell>
                         // {props.row.wrongedDepAirport}
                          // <Autocomplete
                            // options={wrongedDepAirportAutocomp}
                            // noOptionsText={"Søg efter en lufthavn"}
                            // data-testid="wrongedDepAirport-autocomplete"
                            // fullWidth
                            // // loading={true}
                            // onChange={props.wrongedDepAirportChange}
                            // onInputChange={handleAutocompAirportsReq(setWrongedDepAirportAutocomp)}
                            // getOptionLabel={(option) => `${option.name} (${option.iata})`}
                            // renderInput={(params) => <TextField {...params}
                                                        // label={props.row.wrongedDepAirport}
                                                        // size="small"
                                                        // variant="outlined"
                                        // />}
                          // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Ankomst lufthavn
                     // </TableCell>
                     // <TableCell>
                         // {props.row.wrongedArrAirport}
                          // <Autocomplete
                            // options={wrongedArrAirportAutocomp}
                            // noOptionsText={"Søg efter en lufthavn"}
                            // data-testid="wrongedArrAirport-autocomplete"
                            // fullWidth
                            // // loading={true}
                            // onChange={props.wrongedArrAirportChange}
                            // onInputChange={handleAutocompAirportsReq(setWrongedArrAirportAutocomp)}
                            // getOptionLabel={(option) => `${option.name} (${option.iata})`}
                            // renderInput={(params) => <TextField {...params}
                                                        // label={props.row.wrongedArrAirport}
                                                        // size="small"
                                                        // variant="outlined"
                                        // />}
                          // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Email
                     // </TableCell>
                     // <TableCell>
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.email} 
                                    // onChange={props.onChange("email")} />
                         // [> props.row.email <]
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Phone number
                     // </TableCell>
                     // <TableCell>
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.phoneNumber} 
                                    // onChange={props.onChange("phoneNumber")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Regnr
                     // </TableCell>
                     // <TableCell>
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.regNum} 
                                    // onChange={props.onChange("reqNum")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Kontonr
                     // </TableCell>
                     // <TableCell>
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.accountNum}
                                    // onChange={props.onChange("accountNum")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Flyselskab
                     // </TableCell>
                     // <TableCell>
                         // {props.row.specificFlightAirline}
                          // <Autocomplete
                            // options={specificFlightAirlineAutocomp}
                            // noOptionsText={"Søg efter et flyselskab"}
                            // data-testid="specificFlightAirline-autocomplete"
                            // fullWidth
                            // // loading={true}
                            // onChange={props.specificFlightAirlineChange}
                            // onInputChange={handleAutocompAirlineReq(setSpecificFlightAirlineAutocomp)}
                            // getOptionLabel={(option) => `${option.name} (${option.iata})`}
                            // renderInput={(params) => <TextField {...params}
                                                        // label={props.row.specificFlightAirline}
                                                        // size="small"
                                                        // variant="outlined"
                                        // />}
                          // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Fly nummer prefix
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.specificFlightNumPrefix<]
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.specificFlightNumPrefix}
                                    // onChange={props.onChange("specificFlightNumPrefix")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Fly nummer suffix
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.specificFlightNumPrefix<]
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.specificFlightNum}
                                    // onChange={props.onChange("specificFlightNum")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Flynummer
                     // </TableCell>
                     // <TableCell>
                         // {`${props.row.specificFlightNumPrefix}${props.row.specificFlightNum}`}
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Fly dato
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.specificFlightDate ? props.row.specificFlightDate.toString() : ""<]
                         // <LocalizationProvider 
                           // dateAdapter={AdapterDateFns}
                           // locale={da}
                           // localeText={{ cancelButtonLabel: "annuller", previousMonth: "Forrige måned", nextMonth: "Næste måned" }}
                         // >
                           // <MobileDatePicker
                             // label="Dato"
                             // inputFormat="dd/MM/yy"
                             // disableFuture
                             // renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                             // value={props.newRow.specificFlightDate}
                             // onChange={props.dateOnChange("specificFlightDate")}
                           // />
                         // </LocalizationProvider>
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Indhentet fly data
                     // </TableCell>
                     // <TableCell>
                         // [> {
                          // props.row.specificFlightStats
                         // } */}
                         // {
                          // props.row.specificFlightStats ? <Button variant="contained" color="primary" onClick={() => {props.openFlightStatsDialog(props.row.specificFlightStats)}}>Se info</Button> : "N/A"
                         // }
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Fly rute
                     // </TableCell>
                     // <TableCell>
                         // {
                          // props.row.depAirport + " -> " +
                          // // @ts-ignore
                          // props.row.stopOverAirports.reduce((acc: string, airport: string) => acc + airport.iata + " -> ", "") +
                          // props.row.arrAirport
                         // }
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell align="center" colSpan={2}>
                         // Andre informationer
                     // </TableCell>
                   // [> Kompensation <]
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Kompensation eller refusion
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.compensationInputChoice<]
                         // <FormControl size="small">
                             // <Select
                                // value={props.newRow.compensationInputChoice}
                                // onChange={props.onChange("compensationInputChoice")}
                             // >
                             // {
                                // Object.values(CompensationInputChoice).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
                             // }
                             // </Select>
                         // </FormControl>
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Kompensation
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.compensation<]
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.compensation}
                                    // onChange={props.onChange("compensation")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Refusion
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.reimbursment<]
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.reimbursment}
                                    // onChange={props.onChange("reimbursment")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // [> Extra expense <]
                   // <TableRow>
                     // <TableCell>
                         // Ekstra udgifter svar
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.extraExpense<]
                         // <FormControl size="small">
                             // <Select
                                // value={props.newRow.extraExpense === null ? 500 : props.newRow.extraExpense}
                                // onChange={props.onChange("extraExpense")}
                             // >
                             // {
                                // Object.values(Answer).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
                             // }
                             // </Select>
                         // </FormControl>
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Ekstra udgifter
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.expense<]
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.expense}
                                    // onChange={props.onChange("expense")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Kommentar
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.comment<]
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.comment}
                                    // onChange={props.onChange("comment")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // [> IsPresent <]
                   // <TableRow>
                     // <TableCell>
                         // Var til stede
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.isPresent<]
                         // <FormControl size="small">
                             // <Select
                                // value={props.newRow.isPresent === null ? 500 : props.newRow.isPresent}
                                // onChange={props.onChange("isPresent")}
                             // >
                             // {
                                // Object.values(Answer).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
                             // }
                             // <MenuItem value={500}>-</MenuItem>
                             // </Select>
                         // </FormControl>
                     // </TableCell>
                   // </TableRow>
                   // [> DelayMsg <]
                   // <TableRow>
                     // <TableCell>
                         // Hvor forsinket var pax
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.delayMessage<]
                         // <FormControl size="small">
                             // <Select
                                // value={props.newRow.delayMessage}
                                // onChange={props.onChange("delayMessage")}
                             // >
                             // {
                                // Object.values(DelayMsgAnswer).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
                             // }
                             // </Select>
                         // </FormControl>
                     // </TableCell>
                   // </TableRow>
                   // [> DidRebook <]
                   // <TableRow>
                     // <TableCell>
                         // Blev pax tilbudt ombookning af flyselskabet
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.didRebook<]
                         // <FormControl size="small">
                             // <Select
                                // value={props.newRow.didRebook === null ? 500 : props.newRow.didRebook}
                                // onChange={props.onChange("didRebook")}
                             // >
                             // {
                                // Object.values(Answer).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
                             // }
                             // <MenuItem value={500}>-</MenuItem>
                             // </Select>
                         // </FormControl>
                     // </TableCell>
                   // </TableRow>
                   // [> DidFlyRebook <]
                   // <TableRow>
                     // <TableCell>
                         // Fløj pax med ombookningen
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.didFlyRebook<]
                         // <FormControl size="small">
                             // <Select
                                // value={props.newRow.didFlyRebook === null ? 500 : props.newRow.didFlyRebook}
                                // onChange={props.onChange("didFlyRebook")}
                             // >
                             // {
                                // Object.values(Answer).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
                             // }
                             // <MenuItem value={500}>-</MenuItem>
                             // </Select>
                         // </FormControl>
                     // </TableCell>
                   // </TableRow>
                   // [> EndFlightInfo <]
                   // <TableRow>
                     // <TableCell>
                         // Endelige flyvning flyselskab
                     // </TableCell>
                     // <TableCell>
                         // {props.row.endFlightAirline}
                          // <Autocomplete
                            // options={endFlightAirlineAutocomp}
                            // noOptionsText={"Søg efter et flyselskab"}
                            // data-testid="endFlightAirline-autocomplete"
                            // fullWidth
                            // // loading={true}
                            // onChange={props.endFlightAirlineChange}
                            // onInputChange={handleAutocompAirlineReq(setEndFlightAirlineAutocomp)}
                            // getOptionLabel={(option) => `${option.name} (${option.iata})`}
                            // renderInput={(params) => <TextField {...params}
                                                        // label={props.row.endFlightAirline}
                                                        // size="small"
                                                        // variant="outlined"
                                        // />}
                        // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Nye flyvning flynummer prefix
                     // </TableCell>
                     // <TableCell>
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.newFlightNumPrefix} 
                                    // onChange={props.onChange("newFlightNumPrefix")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Nye flyvning flynummer suffix
                     // </TableCell>
                     // <TableCell>
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.newFlightNum} 
                                    // onChange={props.onChange("newFlightNum")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Endelige flyvning flynummer
                     // </TableCell>
                     // <TableCell>
                         // {props.row.endFlightNumPrefix + props.row.endFlightNum}
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Endelige flyvning dato
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.endFlightDate ? props.row.endFlightDate.toString() : ""<]
                         // <LocalizationProvider 
                           // dateAdapter={AdapterDateFns}
                           // locale={da}
                           // localeText={{ cancelButtonLabel: "annuller", previousMonth: "Forrige måned", nextMonth: "Næste måned" }}
                         // >
                           // <MobileDatePicker
                             // label="Dato"
                             // inputFormat="dd/MM/yy"
                             // disableFuture
                             // renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                             // value={props.newRow.endFlightDate}
                             // onChange={props.dateOnChange("endFlightDate")}
                           // />
                         // </LocalizationProvider>
                     // </TableCell>
                   // </TableRow>
                   // [> HasRebook <]
                   // <TableRow>
                     // <TableCell>
                         // Har pax dokumentation for ombookningen
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.hasRebook<]
                         // <FormControl size="small">
                             // <Select
                                // value={props.newRow.hasRebook === null ? 500 : props.newRow.hasRebook}
                                // onChange={props.onChange("hasRebook")}
                             // >
                             // {
                                // Object.values(Answer).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
                             // }
                             // <MenuItem value={500}>-</MenuItem>
                             // </Select>
                         // </FormControl>
                     // </TableCell>
                   // </TableRow>
                   // [> NoDocs <]
                   // <TableRow>
                     // <TableCell>
                         // Ca. ankomst dato til endelige ankomstlufthavn
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.noDocsDate ? props.row.noDocsDate.toString() : ""<]
                         // <LocalizationProvider 
                           // dateAdapter={AdapterDateFns}
                           // locale={da}
                           // localeText={{ cancelButtonLabel: "annuller", previousMonth: "Forrige måned", nextMonth: "Næste måned" }}
                         // >
                           // <MobileDatePicker
                             // label="Dato"
                             // inputFormat="dd/MM/yy"
                             // disableFuture
                             // renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                             // value={props.newRow.noDocsDate}
                             // onChange={props.dateOnChange("noDocsDate")}
                           // />
                         // </LocalizationProvider>
                     // </TableCell>
                   // </TableRow>
                   // [>
                   // <TableRow>
                     // <TableCell>
                         // Ca. ankomst tidspunkt til endelige ankomstlufthavn
                     // </TableCell>
                   // </TableRow>
                    // */}
                   // { /*NoRebook*/ }
                   // <TableRow>
                     // <TableCell>
                         // Pax blev ikke ombooket, hvad gjorde de så
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.noRebook<]
                         // <FormControl size="small">
                             // <Select
                                // value={props.newRow.noRebook === null ? 500 : props.newRow.noRebook}
                                // onChange={props.onChange("noRebook")}
                             // >
                             // {
                                // Object.values(NoRebookingChoice).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
                             // }
                             // <MenuItem value={500}>-</MenuItem>
                             // </Select>
                         // </FormControl>
                     // </TableCell>
                   // </TableRow>
                   // [> GotRefundTicket <]
                   // <TableRow>
                     // <TableCell>
                         // Fik pax refunderet billetprisen for den oprindelige flyvning
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.gotRefundTicket<]
                         // <FormControl size="small">
                             // <Select
                                // value={props.newRow.gotRefundTicket === null ? 500 : props.newRow.gotRefundTicket}
                                // onChange={props.onChange("gotRefundTicket")}
                             // >
                             // {
                                // Object.values(Answer).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
                             // }
                             // <MenuItem value={500}>-</MenuItem>
                             // </Select>
                         // </FormControl>
                     // </TableCell>
                   // </TableRow>
                   // [> Refund <]
                   // <TableRow>
                     // <TableCell>
                         // Refunderet beløb
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.refund<]
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.refund}
                                    // onChange={props.onChange("refund")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // [> NewFlightInfo <]
                   // <TableRow>
                     // <TableCell>
                         // Nye flyvning flyselskab
                     // </TableCell>
                     // <TableCell>
                         // {props.row.newFlightAirline}
                          // <Autocomplete
                            // options={newFlightAirlineAutocomp}
                            // noOptionsText={"Søg efter et flyselskab"}
                            // data-testid="specificFlightAirline-autocomplete"
                            // fullWidth
                            // // loading={true}
                            // onChange={props.newFlightAirlineChange}
                            // onInputChange={handleAutocompAirlineReq(setNewFlightAirlineAutocomp)}
                            // getOptionLabel={(option) => `${option.name} (${option.iata})`}
                            // renderInput={(params) => <TextField {...params}
                                                        // label={props.row.newFlightAirline}
                                                        // size="small"
                                                        // variant="outlined"
                                        // />}
                          // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Nye flyvning flynummer prefix
                     // </TableCell>
                     // <TableCell>
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.newFlightNumPrefix} 
                                    // onChange={props.onChange("newFlightNumPrefix")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Nye flyvning flynummer suffix
                     // </TableCell>
                     // <TableCell>
                         // <TextField variant="standard" 
                                    // defaultValue={props.row.newFlightNum} 
                                    // onChange={props.onChange("newFlightNum")}
                                    // />
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Nye flyvning flynummer
                     // </TableCell>
                     // <TableCell>
                         // {props.row.newFlightNumPrefix + props.row.newFlightNum}
                     // </TableCell>
                   // </TableRow>
                   // <TableRow>
                     // <TableCell>
                         // Nye flyvning dato
                     // </TableCell>
                     // <TableCell>
                         // [>props.row.newFlightDate ? props.row.newFlightDate.toString() : ""<]
                         // <LocalizationProvider 
                           // dateAdapter={AdapterDateFns}
                           // locale={da}
                           // localeText={{ cancelButtonLabel: "annuller", previousMonth: "Forrige måned", nextMonth: "Næste måned" }}
                         // >
                           // <MobileDatePicker
                             // label="Dato"
                             // inputFormat="dd/MM/yy"
                             // disableFuture
                             // renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                             // value={props.newRow.newFlightDate}
                             // onChange={props.dateOnChange("newFlightDate")}
                           // />
                         // </LocalizationProvider>
                     // </TableCell>
                   // </TableRow>
                 // </TableBody>
               // </Table>
             // </TableContainer>
         // </div>
}

// const SelectMenu = (props: { value: any, onChange: (e: any) => void, values: any }) => 
    // <FormControl size="small">
         // <Select
            // value={props.value}
            // onChange={props.onChange}
         // >
         // {
            // Object.values(props.values).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
         // }
         // </Select>
    // </FormControl>


export default InfoTable
