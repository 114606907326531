import {CaseStatusChange} from "../../../utils";
import {Container, List, ListItem, ListItemText, Paper, Accordion, AccordionSummary, AccordionDetails, AccordionActions, Collapse, CircularProgress, Box, Typography } from "@mui/material";
import { ExpandMore, Refresh } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import Linkify from 'react-linkify'

const timeFormatOptions : any = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
}

// Custom formatting function
function formatTime(date: Date) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based in JavaScript
    const year = date.getFullYear();
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export function LogList (props:{logs: Array<any>, refresh: () => Promise<void>, isLoading: boolean}) {
    // Find the latest change and and compute how long ago that was, and create a human readable message with this
    // Get the latest log
    const latestLog = props.logs[props.logs.length - 1];
    let message = 'I';
    if (latestLog) {
        const diff = new Date().getTime() - new Date(latestLog.timestamp).getTime();
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const seconds = Math.floor((diff / 1000) % 60);
        message = `Seneste ændring var for ${days} dage, ${hours} timer, ${minutes} minutter og ${seconds} sekunder siden.`;
    }
    return ( <div>
        <Paper elevation={-1} sx={{
            // bgcolor: 'lightgrey'
            bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            }}>
            {/* <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
                Log
            </AccordionSummary> */}
            {/* <AccordionDetails> */}
        <Container sx={{
            display:"flex",
            flexDirection: "column-reverse",
            overflow: 'auto',
            height: 400,
            maxHeight: 300
        }}>
            <List
                disablePadding
                sx={{
                    width: '100%',
                    // maxWidth: 360,
                    // bgcolor: 'background.paper',
                    position: 'relative',
                    // overflow: 'auto',
                    // maxHeight: 300,
                    '& ul': { padding: 0 },
                    backgroundColor: 'transparent'
                }}
            >   
                <Collapse in={(props.logs !== null && props.logs.length > 0)} timeout={1200}>
                {  props.logs.map((log) => {
                    const date = new Date(log.timestamp)
                    const timeString = formatTime(date)
                    // const timeString = date
                    //     .toLocaleDateString('da', timeFormatOptions)
                    return (
                        <ListItem 
                            key={log.id}
                            // sx={{ marginBottom: '1px'}}
                            sx={{ py: 0.4 }}
                        >
                            <ListItemText
                                // primary={`Changed status from ${log.fromStatus} to ${log.toStatus}`}
                                // primary={log.description}
                                primary={<Linkify>{log.description}</Linkify>}
                                secondary={`${timeString} - ${log.user}`}
                            />
                        </ListItem>
                        )
                })}
            </Collapse>
            </List>
        </Container>
            {/* </AccordionDetails> */}
            {/* <AccordionActions> */}
            <Box sx={{display: 'flex', justifyContent: 'right', padding: 0}}>
            <LoadingButton 
                loading={props.isLoading} 
                startIcon={<Refresh />} 
                size="small" 
                onClick={async () => {await props.refresh()}} 
                loadingIndicator={<CircularProgress color="primary" size={16} />}
            >
                Refresh
            </LoadingButton>
            </Box>
            {/* </AccordionActions> */}
        </Paper>
        {/* <Typography variant="body1">{message}</Typography> */}
        <ListItemText secondary={message || 'I'} />
    </div>
    )
}
