import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import { Typography, Grid, TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper } from '@mui/material'
import { getSignedTemplateReadUrlApi, uploadTemplateFile } from '../../../requests'
import { DocumentTypes } from '../../../enums/DocumentTypes'

const TemplatesPage = () => {

    const [uploadState, setUploadState] = React.useState(false)

    // Very idotic way of checking if logged in
    useEffect(() => {
        // Check if logged in (by we can get read url without error)
        const runPromise = async () => {
            const url = await getSignedTemplateReadUrlApi(DocumentTypes.POA)
            if (!url.ok) {
                console.error(url.err)
                alert("Authorization failed")
                window.location.href = "/login"
            }
        }
        runPromise()
    }, [])


    const handleDownloadClick = async (docType: string) => {
        console.log("Called")
        // Check if docType is in DocumentTypes
        console.log(docType)
        console.log(Object.values(DocumentTypes))
        console.log(Object.values(DocumentTypes).includes(docType as DocumentTypes))
        if (!Object.values(DocumentTypes).includes(docType as DocumentTypes))
            return
        // Call api to get signed url
        console.log("Calling api")
        const url = await getSignedTemplateReadUrlApi(docType)
        console.log(url)
        if (url.ok)
            window.open(url.val, "_blank")
        else
            console.error(url.err)
        // console.log("Download clicked for: " + docType)
    }

    const handleUploadClick = async (docType: string, files: FileList | null) => {
        // Check if docType is in DocumentTypes
        if (!Object.values(DocumentTypes).includes(docType as DocumentTypes))
            return
        // Check if file is selected
        if (files === null || files.length === 0)
            return
        
        const file = files[0]
        // Check if file is docx
        if (file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
            return
        setUploadState(true)
        // Call api to upload file
        const res = await uploadTemplateFile(file, docType)
        if (res.ok) {
            alert("Upload successful")
        } else {
            alert("Upload failed")
            console.log(res.err)
        }
        setUploadState(false)
        // Reload page
        // window.location.reload()
    }

    return <React.Fragment>
                <Typography variant="h2">Templates</Typography>
                {/* Go through each DocumentTypes and create button for download and uploading new one */}
                <Grid container spacing={2} alignContent="center" justifyContent={"center"} justifyItems="center">
                    <Grid item xs={7}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant='body2'>Template name</Typography></TableCell>
                                    <TableCell align="center"><Typography variant='body2'>Download</Typography></TableCell>
                                    <TableCell align="center"><Typography variant='body2'>Upload new</Typography></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {Object.entries(DocumentTypes).map(([key, val]) => (
                                    <TableRow
                                    key={key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell component="th" scope="row">
                                        <Typography variant='body1'>{val}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button variant='contained' onClick={async () => {await handleDownloadClick(val)}}>Download</Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        {/* <Button variant='outlined' onClick={() => {handleUploadClick(val)}}>Upload new</Button> */}
                                        <Button
                                            variant="outlined"
                                            disabled={uploadState}
                                            component="label"
                                        >
                                                {uploadState ? "Uploading..." : "Upload new"}
                                            <input
                                                type="file"
                                                hidden
                                                // accept=".pdf"
                                                onChange={(e) => {handleUploadClick(val, e.target.files)}}
                                            />
                                        </Button>
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
    </React.Fragment>
} 


export default TemplatesPage