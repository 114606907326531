import React, { ReactElement, useState } from "react"
import { TextField,
         Typography,
         Select,
         MenuItem,
         FormControl,
         Autocomplete,
         Button,
         Table,
         TableBody,
         TableRow,
         TableCell,
         TableContainer,
         Dialog,
         DialogTitle,
         DialogContent,
         DialogActions,
         IconButton,
       } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { searchAirports } from "../../../requests"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { setNewStopOverAirport } from "../caseSlice"

import { Airport } from "../../../enums/entities/Airport"

interface AirportAutocompleteOption {
    name: string,
    iata: string,
    country: string,
    city: string,
    countryCode: string,
    ec261: boolean
}
type HandleAirportAutcomFunc = (d: Array<AirportAutocompleteOption>) => void

export const StopOverAirportsView = (props: { id: any, open: boolean, setOpen: (id: any) => void }) => {
    // This line is only here because the name id is extremely vague
    const airports = props.id

    return <Dialog
                open={props.open}
                onClose={props.setOpen}
                scroll={"paper"}
                maxWidth="md"
                fullWidth
                aria-labelledby="stop-over-airport-dialog-title"
           >
                <DialogTitle sx={{ m: 0, p: 2 }} id="stop-over-airport-dialog-title">
                    <Typography>Mellemlandinger</Typography>
                        <IconButton
                            aria-label="close"
                            onClick={() => props.setOpen(false)}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    { airports.length > 0 ? 
                        <TableContainer sx={{maxHeight: 440}}>
                            <Table>
                                <TableBody>
                                    {airports.map((airport: Airport, i: number) =>
                                        <TableRow key={i + "StopOverAirportsViewTableRow"}>
                                            <TableCell key={i + "StopOverAirportsView"}>
                                                Mellemlanding {i + 1}:
                                            </TableCell>
                                            <TableCell key={"StopOverAirportsView" + i}>
                                                <InfoAirportAutocomplete index={i} />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : // This is basically an else clause
                        <Typography>Der er ingen mellemlandinger</Typography>
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => props.setOpen(false)}>
                        OK
                    </Button>
                </DialogActions>
           </Dialog>
}

const InfoAirportAutocomplete = (props: { index: number }) => {
    const dispatch = useAppDispatch()
    const row = useAppSelector(state => state.caseData.newRow)

    const [airportAutocomp, setAirportAutocomp] = 
      useState<Array<AirportAutocompleteOption>>([])

    const handleAutocompAirportsReq = (f: HandleAirportAutcomFunc) => async (e: object) => {
        // @ts-ignore
        const data = await searchAirports(e.target.value)
        if (data.ok)
            f(data.val)
    }

    return <Autocomplete
      options={airportAutocomp}
      noOptionsText={"Søg efter en lufthavn"}
      // data-testid="wrongedArrAirport-autocomplete"
      fullWidth
      // loading={true}
      onChange={(e: any, value: any) => dispatch(setNewStopOverAirport({ index: props.index, value: value }))}
      onInputChange={handleAutocompAirportsReq(setAirportAutocomp)}
      getOptionLabel={(option: any) => `${option.name} (${option.iata})`}
      renderInput={(params) => <TextField {...params}
                                  // @ts-ignore
                                  label={row.stopOverAirports[props.index].iata}
                                  size="small"
                                  variant="outlined"
                  />}
    />
}
