import React, { useState, useEffect } from 'react';
import {Box,
        Button,
        CircularProgress,
        Grid,
        Typography,
        Select,
        FormControl,
        MenuItem,
        Paper
       } from '@mui/material';
import {DataGrid, GridApi, GridColDef} from '@mui/x-data-grid';
import axios from "axios";

import {useNavigate} from "react-router-dom";

import CaseViewPage from "../CaseViewPage/CaseViewPage"

import { getCassesApi, getCaseDataSmall, getCase } from "../../../requests"

// import { IRow } from "../../../utils"
import { Cases } from "../../../enums/entities/Cases"
import { Status } from "../../../enums/caseStatus"

enum StatusFilter {
    Nothing = "Ingen ting",
    NoNotFinished = "Ingen ikke færdiggjorte",
}

const ExcelPage = (props:{}) =>  {

    const navigate = useNavigate()

    const [filterState, setFilterState] = useState<StatusFilter | Status>(StatusFilter.NoNotFinished)

    const [rows, setRows] = useState<Array<any>>([])
    const [newRows, setNewRows] = useState<Array<any>>([])
    const [caseId, setCaseId] = useState<number | null>(null)
    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'CaseID', 
            // flex: 90 
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 150,
        },
        {
            field: 'deadlineType',
            headerName: 'Tætteste frist (type)',
            flex: 200,
        },
        {
            field: 'deadlineDate',
            headerName: 'Tætteste frist (dato)',
            flex: 150,
        },
        {
            field: 'courtCaseId',
            headerName: 'Myndigshedssags nr.',
            flex: 150,
        },
        {
            field: 'specificFlightNumber',
            headerName: 'Flynummer',
            flex: 100,
        },
        {
            field: 'specificFlightDate',
            headerName: 'Flydato (planlagt ankomst)',
            flex: 200,
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            renderCell: ((params: any) => (
                <Button
                    variant={"contained"}
                    onClick={() => { setCaseId(params.id) }}>
                    Åben
                </Button>
            )),

        },
    ]

    // Make request for data
    useEffect(() => {
        // We create a run promise funciton inside of the effect function, because it can not take an async function as parameter
        const runPromise = async () => {
            // Wait two seconds
            await new Promise((resolve) => setTimeout(resolve, 2000))
            // Do the actual job
            const data = await getCaseDataSmall()
            if (data.ok) {
                setRows(data.val)
                setNewRows(filterRows(filterState, data.val))
            }
            else {
                console.error(data.err)
                // alert("Authorization failed")
                navigate("/login")
            }
        }
        runPromise()
    }, [])

    useEffect(() => {
        setNewRows(filterRows(filterState, rows))
    }, [filterState])

    return <Paper elevation={-1}>
    <Box padding={10}>
             <Grid container spacing={2} alignItems='center' justifyContent='center'>
                          
                  {/* <Grid item md={12}>
                      <Typography variant="h4">Case Manager (Excel)</Typography>
                  </Grid> */}
                  {/* {
                      (rows.length > 0) &&  */}
                        <>
                          <Grid item md={12} sx={{ width: '100%'}}>
                              <Box sx={{ width:'100%'}}>
                                  <DataGrid
                                      rows={newRows}
                                      columns={columns}
                                    //   pageSize={25}
                                    //   rowsPerPageOptions={[5]}
                                    //   disableSelectionOnClick
                                      autoPageSize={true}
                                      autoHeight={true}
                                    //   autosizeOnMount={true}
                                    disableAutosize={false}
                                    loading={rows.length <= 0}
                                    rowSelection={false}
                                  />
                              </Box>
                          </Grid>
                          <Grid item md={12}>
                             <FormControl size="small">
                                <Select
                                    value={filterState}
                                    onChange={ (e: any) => { setFilterState(e.target.value) } }
                                >
                                        <MenuItem value={StatusFilter.NoNotFinished}> Ingen ikke færdiggjorte </MenuItem>
                                    { 
                                        Object.values(Status).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
                                    }

                                        <MenuItem value={StatusFilter.Nothing}> - </MenuItem>
                                </Select>
                             </FormControl>
                          </Grid>
                      </>
                  {/* } */}
                  {/* {
                      rows.length <= 0 && <CircularProgress />
                  } */}
              </Grid>
              { caseId !== null &&
                  <CaseViewPage isOpen={caseId !== null} caseId={caseId} handleClose={() => setCaseId(null)} />
              }
            </Box>
                </Paper>
}

const filterRows = (filterState: Status | StatusFilter, rows: Array<any>) => {
    switch (filterState) {
        case StatusFilter.NoNotFinished: 
            return rows.filter(row => row.status !== Status.NotFinished)
        case StatusFilter.Nothing: 
            return rows
        default: 
            return rows.filter(row => row.status === filterState)
    }
}

export default ExcelPage
