import * as React from "react"
import { Button,
         Dialog,
         DialogTitle,
         DialogContent,
         DialogActions,
         IconButton,
         Typography,
         Grid,
         FormControl,
         InputLabel,
         Select,
         MenuItem,
         Table,
         TableContainer,
         TableBody,
         TableRow,
         TableCell,
         Box
       } from "@mui/material"
import { styled } from "@mui/material/styles"
import CloseIcon from "@mui/icons-material/Close"

import { FlightStatsData, createDummy } from "./FlightStatsData"
import { getFlightStatsData, getSignedReadUrlApi } from "../../../../requests"
import { ConstructionOutlined } from "@mui/icons-material"


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    backdropFilter: "blur(5px)",
}))


interface MenuProps {
    handleClose: () => void
    isOpen: boolean
    id: string

    children: any
}


const BootstrapDialogTitle = (props: {id:string, onClose: () => void, children?: React.ReactNode}) => {
    // const { children, onClose, ...other } = props
    const onClose = props.onClose
    const id = props.id

    return (
        // <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        <DialogTitle sx={{ m: 0, p: 2 }} id={id}>
            {props.children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

function FlightStatsDialog(props:MenuProps) {
    return <BootstrapDialog
                onClose={props.handleClose}
                aria-labelledby="flight-stats-dialog-title"
                scroll={"paper"}
                maxWidth="md"
                fullWidth
                open={props.isOpen}>
                <BootstrapDialogTitle id="flight-stats-dialog-title" onClose={props.handleClose}>
                    <Typography>Internal ID: {props.id}</Typography>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {props.children}
                </DialogContent>
            </BootstrapDialog>
}

// Convert data in the form '2023-05-22' to '22/05/2023'
const formatDate = (date:string) => {
    const [year, month, day] = date.split('-')
    return `${day}/${month}/${year}`
}

// Convert data in the form '2023-05-24T14:30:00.066Z' to '14:30'
const formatTime = (date:string) => {
    const [time] = date.split('T')[1].split('.')
    return time.slice(0, -3)
}

export const FlightStatsView = (props: {id:string, open:boolean, setOpen:(open: any) => void}) => {
    const {open, setOpen} = props 
    const [data, setData] = React.useState<FlightStatsData | null>(null)
    const [imageUrl, setImageUrl] = React.useState<string | null>(null)
    const handleClose = () => {
        setOpen(false)
    }
    const retrieveData = async () => {
        const retrievedData = await getFlightStatsData(props.id)
        if (retrievedData.err)
            console.log(retrievedData.val)
        else {
            setData(retrievedData.val)
            if (retrievedData.val.screenshotUrl) {
                const screenshotDownloadUrl = await getSignedReadUrlApi(retrievedData.val.screenshotUrl)
                if (screenshotDownloadUrl.err)
                    console.log(screenshotDownloadUrl.val)
                else
                    setImageUrl(screenshotDownloadUrl.val)
            }
        }
    }
    React.useEffect(() => {
        if (open) {
            // Load flight stats infomation
            retrieveData()
        }
    }, [open])
    return <FlightStatsDialog id={props.id} handleClose={handleClose} isOpen={open}>
            {data ? <FlightInfoTable data={data}></FlightInfoTable> : <Typography>Loading...</Typography>}
            {imageUrl ? <ScreenshotView url={imageUrl}></ScreenshotView> : <Typography>Loading screenshot...</Typography>}
        </FlightStatsDialog>
        
} 

const ScreenshotView = (props: {url: string}) => {
    return <img src={props.url} style={{width: "100%", height: "auto"}}></img>
}

const FlightInfoTable = (props: {data: FlightStatsData}) => {
    const data = props.data
    return <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            <Typography variant="h6" sx={{marginBottom: "0.2rem"}} color={"darkgrey"}>
                {data.airline.name}
            </Typography>
            <Typography variant="h5" sx={{marginBottom: "0.5rem"}}>
                {data.carrierCode} {data.flightNumber}
            </Typography>
            <Typography variant="h6" sx={{marginBottom: "1rem"}}>
                Status: {data.status}
            </Typography>
            <TableContainer sx={{maxHeight: 440}}>
                <Table>
                    <TableBody>

                        <TableRow>
                            <TableCell>
                                Afgangs lufthavn
                            </TableCell>
                            <TableCell>
                                {data.departureAirport.iata}
                            </TableCell>
                            <TableCell>
                                Ankomst lufthavn
                            </TableCell>
                            <TableCell>
                                {data.arrivalAirport.iata}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                Afgangs dato
                            </TableCell>
                            <TableCell>
                                {formatDate(data.departureDate)}
                            </TableCell>
                            <TableCell>
                                Ankomst dato
                            </TableCell>
                            <TableCell>
                                {formatDate(data.arrivalDate)}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                Planlagt afgang
                            </TableCell>
                            <TableCell>
                                {formatTime(data.scheduledDepartureTime)}
                            </TableCell>
                            <TableCell>
                                Planlagt ankomst
                            </TableCell>
                            <TableCell>
                                {formatTime(data.scheduledArrivalTime)}
                            </TableCell>
                        </TableRow>


                        <TableRow>
                            <TableCell>
                                Faktisk afgang
                            </TableCell>
                            <TableCell>
                                {data.actualDepartureTime ? formatTime(data.actualDepartureTime) : "N/A"}
                            </TableCell>
                            <TableCell>
                                Faktisk ankomst
                            </TableCell>
                            <TableCell>
                                {data.actualArrivalTime ? formatTime(data.actualArrivalTime) : "N/A"}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                Afgangs terminal
                            </TableCell>
                            <TableCell>
                                {data.departureTerminal ? data.departureTerminal: "N/A"}
                            </TableCell>
                            <TableCell>
                                Ankomst terminal
                            </TableCell>
                            <TableCell>
                                {data.arrivalTerminal ? data.arrivalTerminal: "N/A"}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                Afgangs gate
                            </TableCell>
                            <TableCell>
                                {data.departureGate ? data.departureGate : "N/A"}
                            </TableCell>
                            <TableCell>
                                Ankomst gate
                            </TableCell>
                            <TableCell>
                                {data.arrivalGate ? data.arrivalGate : "N/A"}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
}
