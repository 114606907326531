import React, { useEffect, useState } from "react"
import axios from "axios"
import {
    Grid,
    Container,
    Box,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Typography,
    TextField,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Button,
    ButtonGroup, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Stack,
    Divider,
    Snackbar,
    Accordion,
    AccordionSummary,
    AccordionDetails
    // Paper
} from "@mui/material"
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { da } from "date-fns/locale"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import BlurredDialog from "./BlurredDialog"
import { CaseStatusChange } from "../../../utils"
import { Cases } from "../../../enums/entities/Cases"
import { Status } from "../../../enums/caseStatus"
import { CaseAutomationLevel } from "../../../enums/caseAutomation"

import InfoTable from "./InfoTable"
import ConfirmationDialog from "./ConfirmationDialog"
import { LogList } from "./LogList"
import { FlightStatsView } from "./FlightStatsView/FlightStatsDialog";
import { ActionKey } from "../../../enums/ActionKey"

import { useAppDispatch, useAppSelector } from "../../../hooks"
import { setRow, setNewRow } from "../caseSlice"

import { 
    getSignedReadUrlApi,
    getFilesApi,
    changeStatusApi,
    changeAutomationLevelApi,
    getLogsApi,
    getLogs,
    getCase,
    FileReq as File,
    FileGroup,
    Files,
    replaceCase,
    addDeadline,
    getDeadlines,
    deleteDeadline,
    runAction,
    getActionParameters
} from "../../../requests"

import ActionInputComponentMap from "./ActionInputs/ActionInputesMap";
import LoadingButton from '@mui/lab/LoadingButton';
import { set } from "date-fns";


// interface IRow {
    // case_id: string,
    // reason: string,
    // dep_airport: string,
    // arr_airport: string,
    // stop_overs: string,
    // status: string,
    // delay_msg: string,
    // email: string,
    // phone_num: string
// }

/////////////////////////
// Interface for logs
// Should be moved to separate file
// enum LogCategory {
//     Info,
//     StatusChange,
//     DataChange,
//     Error
// }
//
// interface CaseLogEntry {
//     entryId: number;
//     timestamp: Date;
//     entryType: LogCategory;
//     description: string;
//     // caseId: Cases;
// }
//////////////////////////

function DocsList(props: {files: Files | null}) {
  // Returns the files rendered if they are defined, otherwise it returns an empty Element
  const renderFiles = () => {
    return props.files ? props.files.map((group: FileGroup) => 
        <React.Fragment key={group.name}>
          <ListItem>
            <Typography variant="h6" color={"grey"}>{group.name}</Typography>
          </ListItem>
          { 
            group.files.map((file: File) => 
              <ListItem disablePadding key={file.name}>
                <ListItemButton onClick={async () => {
                    const url = await getSignedReadUrlApi(file.id)
                    if (url.ok)
                        window.open(url.val, "_blank")
                }}>
                  <ListItemText primary={file.name} />
                </ListItemButton>
              </ListItem>) 
          }
        </React.Fragment>) 
        :
        <></>
  }
  return <List>
           { renderFiles() }
         </List>
}

// FIXME: Making the id an optional field, means that you have to reload to remove the deadline
type Deadline = { id: number, date: Date, deadline: string }
const DeadlineTable = (props: { caseId: number }) => {
    const [deadlines, setDeadlines] = useState<Array<Deadline>>([])
    
    const [date, setDate] = useState<Date | null>(null)
    const [deadline, setDeadline] = useState<string>("")

    useEffect(() => {
        const runPromise = async () => {
            const deadlines = await getDeadlines(props.caseId)
            if (deadlines.ok)
                setDeadlines(deadlines.val)
        }

        runPromise()
    }, [])
    
    return <TableContainer>
               <Table>
                   <TableBody>
                       <>
                       {
                            deadlines.map((d, i) => 
                                <TableRow key={i}>
                                    <TableCell>
                                        { `${d.date.getDate()}/${d.date.getMonth() + 1}/${d.date.getFullYear()}` }
                                    </TableCell>
                                    <TableCell>
                                        { d.deadline }
                                    </TableCell>
                                    <TableCell>
                                        <Button 
                                            variant="outlined"
                                            onClick={() => { 
                                                if (d.id === undefined) return

                                                deleteDeadline(d.id)
                                                deadlines.splice(i, 1)
                                                setDeadlines([...deadlines])
                                            }}
                                        >
                                            Slet
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                       }
                       </>
                       <TableRow>
                            <TableCell>
                                <LocalizationProvider 
                                  dateAdapter={AdapterDateFns}
                                  locale={da}
                                  localeText={{ cancelButtonLabel: "annuller", previousMonth: "Forrige måned", nextMonth: "Næste måned" }}
                                >
                                    <MobileDatePicker
                                      label="Dato"
                                      inputFormat="dd/MM/yy"
                                      renderInput={(params) => <TextField variant="standard" {...params} />}
                                      value={date}
                                      onChange={(date) => { setDate(date) }}
                                    />
                                </LocalizationProvider>
                            </TableCell>
                            <TableCell>
                                <TextField variant="standard" 
                                           // defaultValue={}
                                           label="Deadline"
                                           value={deadline}
                                           onChange={(e: any) => { setDeadline(e.target.value) }}
                                           />
                            </TableCell>
                            <TableCell>
                                <Button 
                                    variant="contained"
                                    disabled={deadline === "" || date === null}
                                    onClick={async () => { if (date !== null && deadline !== "") {
                                                               const id = await addDeadline(props.caseId, date, deadline)
                                                               if (id.ok) {
                                                                   deadlines.push({ id: id.val, date, deadline })
                                                                   setDeadlines(deadlines) 
                                                                   setDeadline("")
                                                                   setDate(null)
                                                               }
                                                           }
                                                    }}
                                >
                                    Ny
                                </Button>
                            </TableCell>
                       </TableRow>
                   </TableBody>
               </Table>
           </TableContainer>
}

function ControlPanel(props: {initStatus: Status, initAutomationLevel: CaseAutomationLevel, caseId: number}) {
    const [action, setAction] = useState<ActionKey>(ActionKey.GenerateAirlineEmail)
    const ActionInputComponent = ActionInputComponentMap[action];

    const [actionInputParams, setActionInputParams] = useState<any>({})

    const [isAwaitingActionResponse, setIsAwaitingActionResponse] = useState<boolean>(false)

    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const [snackBarText, setSnackBarText] = useState<string>("");

    const openSnackBar = (text: string) => {
        setSnackBarText(text)
        setSnackBarOpen(true)
    }
    
    const handleSnackBarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }

    setSnackBarOpen(false);
    };
    

    return <div>
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <Typography variant="body1">Vælg en action, derefter tryk <Typography variant="body1" fontStyle={"italic"} style={{ display: 'inline' }}>Kør</Typography></Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={1}>
                        <Grid item md={6}>
                            <Select
                                labelId="run-action-label"
                                id="run-action"
                                fullWidth
                                value={action}
                                onChange={
                                    async (e: SelectChangeEvent<ActionKey>) => {
                                        setAction(e.target.value as ActionKey)
                                        // Get action parameters
                                        console.log("Hello from the other side")
                                        const actionParameters = await getActionParameters(props.caseId, e.target.value as ActionKey)
                                        if (actionParameters.ok) {
                                            setActionInputParams(actionParameters.val)
                                        } else {
                                            setActionInputParams({})
                                        }
                                    }}
                            >
                                {
                                    (Object.values(ActionKey))
                                        .map((actionKey) => {
                                        return <MenuItem key={actionKey} value={actionKey}>{actionKey}</MenuItem>
                                    })
                                }
                            </Select>
                        </Grid>
                        <Grid item md={4}>
                            {/* <Button variant="contained" onClick={() => { runAction(props.caseId, action) }}>Kør</Button> */}
                            <LoadingButton
                                variant="contained" 
                                onClick={async () => { 
                                    setIsAwaitingActionResponse(true)
                                    const res = await runAction(props.caseId, action, actionInputParams)
                                    setIsAwaitingActionResponse(false)
                                    if (res.ok) {
                                        openSnackBar("Action started: " + action)
                                    } else {
                                        openSnackBar("Action request failed " + action)
                                    }
                                 }}
                                fullWidth
                                style={{ height: '100%' }}
                                loading={isAwaitingActionResponse}
                            >
                                Kør
                            </LoadingButton>
                            <Snackbar
                                open={snackBarOpen}
                                autoHideDuration={6000}
                                onClose={handleSnackBarClose}
                                message={snackBarText}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    { ActionInputComponent ? ActionInputComponent({state: actionInputParams, setState: setActionInputParams}) : null }
                </Grid>
            </Grid>
                  { /*
                  <Grid item md={12}>
                  <Typography variant="h6">Skift status</Typography>
                  <FormControl fullWidth>
                      <Select
                          labelId="select-status-label"
                          id="select-stats"
                          value={status}
                          onChange={(e: SelectChangeEvent<Status>) => {askStatusChange(e.target.value as Status)}}
                      >
                          {
                              (Object.values(Status))
                                  .map((status) => {
                                      // console.log(value,index)
                                  return <MenuItem value={status}>{status}</MenuItem>
                              })
                          }
                      </Select>
                  </FormControl>
              </Grid>
                  */ }
              {/*
              <Grid item md={12}>
                  <Typography variant="h6">Skift automation level</Typography>
                  <FormControl fullWidth>
                      <Select
                          labelId="select-automation-level-label"
                          id="select-auto-level"
                          value={automationLevel}
                          onChange={(e: SelectChangeEvent<CaseAutomationLevel>) => {askAutomationLevelChange(e.target.value as CaseAutomationLevel)}}
                      >
                          {
                              (Object.values(CaseAutomationLevel))
                                  .map((automationLevel) => {
                                      // console.log(value,index)
                                  return <MenuItem value={automationLevel}>{automationLevel}</MenuItem>
                              })
                          }
                      </Select>
                  </FormControl>
              </Grid>
              */}
        {/* Used for confirmation of change of status*/}
        { /*
        <ConfirmationDialog
            handleAgree={changeStatus}
            handleDisagree={() => {}}
            text={"Er du sikker på du vil skifte status på denne case?"}
            open={openConfirmDialog}
            handleClose={() => {setOpenConfirmDialog(false)}}/>
        */ }
        { /*
        <ConfirmationDialog
            handleAgree={changeAutomationLevel}
            handleDisagree={() => {}}
            text={"Er du sikker på du vil skifte automation level på denne case?"}
            open={openConfirmDialogAuto}
            handleClose={() => {setOpenConfirmDialogAuto(false)}}/>
        */ }
    </div>
}

interface CaseViewProps {
    isOpen: boolean
    caseId: number
    handleClose: () => void
}

const CaseViewPage = (props: CaseViewProps) => {
    const dispatch = useAppDispatch()

    const [files, setFiles] = useState<Files | null>(null)

    const [flightDialogOpen, setFlightDialogOpen] = useState<boolean>(false)
    const [flightId, setFlightId] = useState<string>("0")

    // FIXME: Make log type
    const [logs, setLogs] = useState<Array<any>>([])
    const [isLogsLoading, setLogsLoading] = useState<boolean>(true)

    const [status, setStatus] = useState<Status | null>(null)
    const [automation, setAutomation] = useState<CaseAutomationLevel | null>(null)

    const row = useAppSelector(state => state.caseData.row)
    const newRow = useAppSelector(state => state.caseData.newRow)

    const [isEnable, enableButton] = useState<boolean>(false)

    const changeStatus = async (e: any) => {
        if (row === null) return
        if ((await changeStatusApi(row.caseId, e.target.value)).ok) {
            setStatus(e.target.value)
            // TODO: Should maybe call a reload data function
            // which would be given in the props
            // Very ugly way of handling UI
            alert("Changed status successfully, reload to see changes")
        } else {
            alert("Failed to change status")
        }
    }

    const changeAutomationLevel = async (e: any) => {
        if (row === null) return
        if ((await changeAutomationLevelApi(row.caseId, e.target.value)).ok) {
            setAutomation(e.target.value)
            // TODO: Should maybe call a reload data function
            // which would be given in the props
            // Very ugly way of handling UI
            alert("Changed automation level successfully, reload to see changes")
        } else {
            alert("Failed to change automation level")
        }
    }

    const saveCase = async () => {
        if (newRow === null) return
        const res = await replaceCase(newRow)
        if (res.err) console.log(res.val)
    }

    const refreshLogs = async () => {
        if (row === null) return
        setLogsLoading(true)
        const logs = await getLogs(row.caseId)
        if (logs.ok) {setLogs(logs.val)}
        setLogsLoading(false)
    }

    useEffect(() => {
        const runPromises = async () => {
            const data = await getCase(props.caseId)
            if (data.ok) {
                dispatch(setRow(data.val))
                dispatch(setNewRow(data.val))
                setAutomation(data.val.automationLevel)
                setStatus(data.val.status)

                const files = await getFilesApi(data.val.caseId)
                if (files.ok) setFiles(files.val)
                else setFiles(null)

                const logs = await getLogs(data.val.caseId)
                if (logs.ok) setLogs(logs.val)
                setLogsLoading(false)
            }
        }

        runPromises()
    }, [])

    useEffect(() => {
        enableButton(row !== newRow)
    }, [newRow])

    return <>
    { row !== null && newRow !== null &&
        <BlurredDialog 
            isOpen={props.isOpen} 
            title={"FS"+row.caseId}
            handleClose={props.handleClose}

            automation={row.automationLevel}
            changeAutomation={changeAutomationLevel}

            status={row.status} 
            changeStatus={changeStatus}
            noOkButton={true}
        >
         <Container>
           <FlightStatsView id={flightId} open={flightDialogOpen} setOpen={setFlightDialogOpen}/>

           <Grid container spacing={12}>
               <Grid item md={6}>
                   <Stack 
                        spacing={2}
                        divider={<Divider orientation="horizontal" flexItem />}
                    >
                       <div>
                         {/* <Typography variant="h4">Info</Typography> */}
                         <Button variant="contained" 
                                 disabled={!isEnable}
                                 onClick={saveCase}
                         >
                            Gem info
                         </Button>
                         <InfoTable 
                            openFlightStatsDialog={(id:any) => {
                                setFlightId(id)
                                setFlightDialogOpen(true)
                            }}
                            onChange={(item: string) => (e: any) => 
                            { 
                                if (newRow !== null) {
                                    // @ts-ignore
                                    newRow[item] = e.target.value
                                    setNewRow({ ...newRow }) 
                                }
                            }}
                            wrongedDepAirportChange={(e: any, value: any) => {
                                if (newRow !== null) {
                                    newRow["wrongedDepAirport"] = value.iata
                                    setNewRow({ ...newRow })
                                }
                            }}
                            wrongedArrAirportChange={(e: any, value: any) => {
                                if (newRow !== null) {
                                    newRow["wrongedArrAirport"] = value.iata
                                    setNewRow({ ...newRow })
                                }
                            }}
                            specificFlightAirlineChange={(e: any, value: any) => {
                                if (newRow !== null) {
                                    newRow["specificFlightAirline"] = value.name
                                    setNewRow({ ...newRow })
                                }
                            }}
                            newFlightAirlineChange={(e: any, value: any) => {
                                if (newRow !== null) {
                                    newRow["newFlightAirline"] = value.name
                                    setNewRow({ ...newRow })
                                }
                            }}
                            endFlightAirlineChange={(e: any, value: any) => {
                                if (newRow !== null) {
                                    newRow["endFlightAirline"] = value.name
                                    setNewRow({ ...newRow })
                                }
                            }}

                            dateOnChange={(item: string) => (date) => {
                                if (newRow !== null && date !== null) {
                                    // @ts-ignore
                                    newRow[item] = date
                                    setNewRow({ ...newRow })
                                }
                            }}
                            // autocompleteChange={(item: string) => (e: any, value: any) => 
                            // {
                                // if (newRow !== null) {
                                    // newRow[item] = 
                                // }
                            // }}
                         />
                       </div>
                       <div>
                           {/* <Typography variant="h4">Kontrol Panel</Typography> */}
                           <ControlPanel initStatus={row.status} caseId={row.caseId} initAutomationLevel={row.automationLevel}/>
                       </div>
                   </Stack>
               </Grid>
               <Grid item md={6}>
                   <Stack 
                        spacing={2}
                        // divider={<Divider orientation="horizontal" flexItem />}
                    >
                       <div>
                            {/* <Typography variant="h6">Deadline</Typography> */}
                            <DeadlineTable caseId={row.caseId} />
                       </div>
                       <div>
                           {/* <Typography variant="h4">Logs</Typography> */}
                           <div>
                               <LogList logs={logs} refresh={refreshLogs} isLoading={isLogsLoading}/>
                           </div>
                       </div>
                       <div>
                       <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                                Filer
                            </AccordionSummary>
                            <AccordionDetails>
                                {/* <Typography variant="h6">Filer</Typography> */}
                                <DocsList files={files} />
                            </AccordionDetails>
                        </Accordion>
                       </div>
                   </Stack>
               </Grid>
           </Grid>
         </Container>
       </BlurredDialog>
    }
     </>
}

export default CaseViewPage
