import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Collapse, List, ListItem } from "@mui/material";
import { useNavigate } from 'react-router-dom';

function StartPage(props:{})  {
    const navigate = useNavigate()
    useEffect(() => {
        // Navigate to the excel page
        navigate("/excel/")
    })
    return <List>
            <ListItem>
                <Button size="large" href="/excel" fullWidth variant="contained">Excel</Button>
            </ListItem>
        </List>
}

export default StartPage
