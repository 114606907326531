export enum ActionKey {
    ValidateBookingConfirmation = "ValidateBookingConfirmation",
    ValidateSignature = "ValidateSignature",
    ValidateCaseClaim = "ValidateCaseClaim",
    GenerateAirlineEmail = "GenerateAirlineEmail",
    SendConfirmationEmail = "SendConfirmationEmail",
    GenerateSubpoena = "GenerateSubpoena",
    ComputeAltRoutes = "ComputeAltRoutes",
    PullDataFromBookingConfirmation = "PullDataFromBookingConfirmation",
    DecorateCase = "DecorateCase",
    GenerateTransferAgreement = "GenerateTransferAgreement"
}
