import * as React from "react"
import { Button,
         Dialog,
         DialogTitle,
         DialogContent,
         DialogActions,
         IconButton,
         Typography,
         Grid,
         FormControl,
         InputLabel,
         Select,
         MenuItem
       } from "@mui/material"
import { styled } from "@mui/material/styles"
import CloseIcon from "@mui/icons-material/Close"

import { Status } from "../../../enums/caseStatus"
import { CaseAutomationLevel } from "../../../enums/caseAutomation"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    backdropFilter: "blur(5px)",
}))

interface DialogTitleProps {
    id: string
    children?: React.ReactNode
    onClose: () => void
}

interface MenuProps {
    handleClose: () => void
    isOpen: boolean
    title: string

    status: Status
    changeStatus: (a: any) => void

    automation: CaseAutomationLevel
    changeAutomation: (a: any) => void

    children: any

    noOkButton?: boolean
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

function BlurredDialog(props:MenuProps) {
    return <BootstrapDialog
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                scroll={"paper"}
                maxWidth="xl"
                fullWidth
                open={props.isOpen}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    <Grid container spacing={12}>
                      <Grid item xs={4}>
                        {props.title}
                      </Grid>
                      <Grid item xs={4}>
                        <StatusSelect value={props.status} onChange={props.changeStatus} />
                      </Grid>
                      <Grid item xs={4}>
                        <AutomationSelect value={props.automation} onChange={props.changeAutomation} />
                      </Grid>
                    </Grid>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {props.children}
                </DialogContent>
                {
                    !props.noOkButton &&
                    <DialogActions>
                        <Button autoFocus onClick={props.handleClose}>
                            OK
                        </Button>
                    </DialogActions>
                }
            </BootstrapDialog>
}

const StatusSelect = (props: { value: Status, onChange: (a: any) => void }) => {
  return <FormControl sx={{minWidth: 240}}>
           <InputLabel id="status-select-label">Status</InputLabel>
           <Select
             labelId="status-select-label"
             id="status-select"
             value={props.value}
             label="Status"
             onChange={props.onChange}
           >
           {
                Object.values(Status).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
           }
           </Select>
         </FormControl>
}

const AutomationSelect = (props: { value: any, onChange: (a: any) => void }) => {
  return <FormControl sx={{minWidth: 240}}>
           <InputLabel id="automation-select-label">Gun control</InputLabel>
           <Select
             labelId="automation-select-label"
             id="automation-select"
             value={props.value}
             label="Gun control"
             onChange={props.onChange}
           >
           {
                Object.values(CaseAutomationLevel).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
           }
           </Select>
         </FormControl>
}

export default BlurredDialog
