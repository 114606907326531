import React, { ReactElement, useState } from "react"
import { TextField,
         Typography,
         Button,
         Dialog,
         DialogTitle,
         DialogContent,
         DialogActions,
         Stack,
         IconButton,
       } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { setNewBookingRef } from "../caseSlice"
import { useAppDispatch, useAppSelector } from "../../../hooks"

export const BookingRefView = (props: { id: any, open: boolean, setOpen: (id: any) => void }) => {
    const dispatch = useAppDispatch()
    // This line is only here because the name id is extremely vague
    const refArr: Array<string> = props.id

    return <Dialog
                open={props.open}
                onClose={props.setOpen}
                scroll={"paper"}
                maxWidth="md"
                fullWidth
                aria-labelledby="pax-dialog-title"
           >
                <DialogTitle sx={{ m: 0, p: 2 }} id="pax-dialog-title">
                    <Typography>Booking referencer</Typography>
                        <IconButton
                            aria-label="close"
                            onClick={() => props.setOpen(false)}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    { refArr !== null ?
                            refArr.map((ref: string, i: number) =>
                                <Stack key={i+"BookingRefView"} spacing={2}>
                                    <TextField 
                                        variant="standard" 
                                        // @ts-ignore
                                        defaultValue={ref} 
                                        onChange={(e: any) => 
                                            dispatch(setNewBookingRef({ 
                                                index: i,
                                                value: e.target.value,
                                            }))
                                        } 
                                    />
                                </Stack>
                    )
                    :
                        <Typography>Der er ingen booking referencer</Typography>
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => props.setOpen(false)}>
                        OK
                    </Button>
                </DialogActions>
           </Dialog>
}
