import React, { ReactElement, useState } from "react"
import { TextField,
         Typography,
         Select,
         MenuItem,
         FormControl,
         Autocomplete,
         Button,
         Dialog,
         DialogTitle,
         DialogContent,
         DialogActions,
         IconButton,
         Divider,
         Grid,
         Switch
       } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { Collection } from '@mikro-orm/core'
import { Pax } from "../../../enums/entities/Pax"

import { setNewPax } from "../caseSlice"
import { useAppDispatch, useAppSelector } from "../../../hooks"

export const PaxView = (props: { id: any, open: boolean, setOpen: (id: any) => void }) => {
    const dispatch = useAppDispatch()
    // This line is only here because the name id is extremely vague
    const paxArr: Array<Pax> = props.id

    return <Dialog
                open={props.open}
                onClose={props.setOpen}
                scroll={"paper"}
                maxWidth="md"
                fullWidth
                aria-labelledby="pax-dialog-title"
           >
                <DialogTitle sx={{ m: 0, p: 2 }} id="pax-dialog-title">
                    <Typography>Pax info</Typography>
                        <IconButton
                            aria-label="close"
                            onClick={() => props.setOpen(false)}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    { 
                            paxArr.map((pax: Pax, i: number) =>
                                <Grid key={i+"PaxView"} container spacing={2}>
                                <Grid item md={6}>
                                    Navn
                                </Grid>
                                <Grid item md={6}>
                                    <TextField variant="standard" 
                                               // @ts-ignore
                                               defaultValue={pax.firstName} 
                                               onChange={(e: any) => 
                                                dispatch(setNewPax({ index: i, 
                                                                     fieldToChange: "firstName",
                                                                     value: e.target.value,
                                                                   }))
                                                } 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    Efternavn
                                </Grid>
                                <Grid item md={6}>
                                    <TextField variant="standard" 
                                               // @ts-ignore
                                               defaultValue={pax.lastName} 
                                               onChange={(e: any) => 
                                                dispatch(setNewPax({ index: i, 
                                                                     fieldToChange: "lastName",
                                                                     value: e.target.value,
                                                                   }))
                                                } 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    Addresse
                                </Grid>
                                <Grid item md={6}>
                                    <TextField variant="standard" 
                                               // @ts-ignore
                                               defaultValue={pax.address} 
                                               onChange={(e: any) => 
                                                dispatch(setNewPax({ index: i, 
                                                                     fieldToChange: "address",
                                                                     value: e.target.value,
                                                                   }))
                                                } 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    By
                                </Grid>
                                <Grid item md={6}>
                                    <TextField variant="standard" 
                                               // @ts-ignore
                                               defaultValue={pax.city} 
                                               onChange={(e: any) => 
                                                dispatch(setNewPax({ index: i, 
                                                                     fieldToChange: "city",
                                                                     value: e.target.value,
                                                                   }))
                                                } 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    Postkode
                                </Grid>
                                <Grid item md={6}>
                                    <TextField variant="standard" 
                                               // @ts-ignore
                                               defaultValue={pax.postCode} 
                                               onChange={(e: any) => 
                                                dispatch(setNewPax({ index: i, 
                                                                     fieldToChange: "postCode",
                                                                     value: e.target.value,
                                                                   }))
                                                } 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    Land
                                </Grid>
                                <Grid item md={6}>
                                    <TextField variant="standard" 
                                               // @ts-ignore
                                               defaultValue={pax.country} 
                                               onChange={(e: any) => 
                                                dispatch(setNewPax({ index: i, 
                                                                     fieldToChange: "country",
                                                                     value: e.target.value,
                                                                   }))
                                                } 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    CPR
                                </Grid>
                                <Grid item md={6}>
                                    <TextField variant="standard" 
                                               // @ts-ignore
                                               defaultValue={pax.cprNumber} 
                                               onChange={(e: any) => 
                                                dispatch(setNewPax({ index: i, 
                                                                     fieldToChange: "cprNumber",
                                                                     value: e.target.value,
                                                                   }))
                                                } 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    Over 18
                                </Grid>
                                <Grid item md={6}>
                                    <Switch 
                                        checked={pax.over18}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                                         dispatch(setNewPax({ index: i, 
                                                              fieldToChange: "over18",
                                                              value: e.target.checked,
                                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    Værge fornavn
                                </Grid>
                                <Grid item md={6}>
                                    <TextField variant="standard" 
                                               // @ts-ignore
                                               defaultValue={pax.guardianFirstName} 
                                               onChange={(e: any) => 
                                                dispatch(setNewPax({ index: i, 
                                                                     fieldToChange: "guardianFirstName",
                                                                     value: e.target.value,
                                                                   }))
                                                } 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    Værge efternavn
                                </Grid>
                                <Grid item md={6}>
                                    <TextField variant="standard" 
                                               // @ts-ignore
                                               defaultValue={pax.guardianLastName} 
                                               onChange={(e: any) => 
                                                dispatch(setNewPax({ index: i, 
                                                                     fieldToChange: "guardianLastName",
                                                                     value: e.target.value,
                                                                   }))
                                                } 
                                        />
                                </Grid>
                                <Grid item md={6}>
                                    Er ejer
                                </Grid>
                                <Grid item md={6}>
                                    <Switch 
                                        checked={pax.isOwner}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                                         dispatch(setNewPax({ index: i, 
                                                              fieldToChange: "isOwner",
                                                              value: e.target.checked,
                                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Divider />
                                </Grid>
                                </Grid>
                            )}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => props.setOpen(false)}>
                        OK
                    </Button>
                </DialogActions>
           </Dialog>
}
