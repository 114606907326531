import React, { ReactElement, useState } from "react"
import { Cases } from "./enums/entities/Cases" 
import { TextField,
         Select,
         MenuItem,
         FormControl,
         Autocomplete,
         Button,
         Table,
         TableBody,
         TableRow,
         TableCell,
         Dialog,
         DialogTitle,
         DialogContent,
         DialogActions,
         IconButton,
         Typography,
       } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { searchAirports, searchAirlines } from "./requests"
import DelayMsgAnswer from "./enums/DelayMsgAnswer"
import { Answer } from "./enums/utils"
import NoRebookingChoice from "./enums/NoRebookingChoice"
import CompensationInputChoice from "./enums/CompensationInputChoice"
import ReasonType from "./enums/ReasonType"
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { da } from "date-fns/locale"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { setNewRowField } from "./components/pages/caseSlice"
import { useAppDispatch, useAppSelector } from "./hooks"

import { FlightStatsView } from "./components/pages/CaseViewPage/FlightStatsView/FlightStatsDialog"
import { StopOverAirportsView } from "./components/pages/CaseViewPage/StopOverAirportsView"
import { PaxView } from "./components/pages/CaseViewPage/PaxView"
import { BookingRefView } from "./components/pages/CaseViewPage/BookingRefView"

interface AirportAutocompleteOption {
    name: string,
    iata: string,
    country: string,
    city: string,
    countryCode: string,
    ec261: boolean
}
type HandleAirportAutcomFunc = (d: Array<AirportAutocompleteOption>) => void
type HandleAirlineAutcomFunc = (d: Array<any>) => void
type CasesCells = {
    [Value in keyof Omit<Required<Cases>, "caseId" 
                                        | "timestamp" 
                                        | "automationLevel" 
                                        | "status" 
                                        | "subStatus" 
                                        | "claimType" 
                                        | "distanceRange" 
                                        | "distance" 
                                        | "delayedByHours" 
                                        | "cancelledRebookType" 
                                        | "expensePayoutPaxIndex" 
                                        | "airlineAnswerType">]: 
    { label: string, element: ReactElement }
}

const toReactElements = (): CasesCells => {
    return {
        depAirport: { label: "Afgangs lufthavn",
                      element: <InfoAirportAutocomplete fieldToChange="depAirport" /> },

        arrAirport: { label: "Afgangs lufthavn",
                      element: <InfoAirportAutocomplete fieldToChange="arrAirport" /> },

        delayMessage: { label: "Hvor forsinket var pax",
                        element: <InfoSelect fieldEnum={DelayMsgAnswer} fieldToChange="delayMessage" /> },

        reason: { label: "Kompensation eller refusion",
                  element: <InfoSelect fieldEnum={ReasonType} fieldToChange="reason" /> },

        compensationInputChoice: { label: "Kompensation eller refusion",
                                   element: <InfoSelect fieldEnum={CompensationInputChoice} fieldToChange="compensationInputChoice" /> },

        extraExpense: { label: "Ekstra udgifter svar",
                        element: <InfoSelect fieldEnum={Answer} fieldToChange="extraExpense" /> },

        isPresent: { label: "Var til stede",
                     element: <InfoSelect fieldEnum={Answer} fieldToChange="isPresent" /> },

        gotRefundTicket: { label: "Fik pax refunderet billetprisen for den oprindelige flyvning",
                           element: <InfoSelect fieldEnum={Answer} fieldToChange="gotRefundTicket" /> },

        noRebook: { label: "Pax blev ikke ombooket, hvad gjorde de så",
                    element: <InfoSelect fieldEnum={NoRebookingChoice} fieldToChange="noRebook" /> },

        didRebook: { label: "Blev pax tilbudt ombookning af flyselskabet",
                     element: <InfoSelect fieldEnum={Answer} fieldToChange="didRebook" /> },

        didFlyRebook: { label: "Fløj pax med ombookningen",
                        element: <InfoSelect fieldEnum={Answer} fieldToChange="didFlyRebook" /> },

        hasRebook: { label: "Har pax dokumentation for ombookningen",
                        element: <InfoSelect fieldEnum={Answer} fieldToChange="hasRebook" /> },

        passengers: { label: "Se pax",
                      element: <InfoDialog fieldToChange="passengers" Dialog={PaxView} /> },

        specificFlightNumPrefix: { label: "Fly nummer prefix",
                                   element: <InfoTextField fieldToChange={"specificFlightNumPrefix"} /> },

        specificFlightNum: { label: "Fly nummer suffix",
                                   element: <InfoTextField fieldToChange={"specificFlightNum"} /> },

        specificFlightDate: { label: "Fly dato",
                              element: <InfoDate fieldToChange="specificFlightDate" /> },

        specificFlightAirline: { label: "Flyselskab",
                                 element: <InfoAirlineAutocomplete fieldToChange="specificFlightAirline" /> },

        specificFlightStats: { label: "Indhentet fly data",
                               element: <InfoDialog fieldToChange="specificFlightStats" Dialog={FlightStatsView} /> },


        newFlightNumPrefix: { label: "Nye flyvning flynummer prefix",
                              element: <InfoTextField fieldToChange={"newFlightNumPrefix"} /> },

        newFlightNum: { label: "Nye flyvning flynummer suffix",
                        element: <InfoTextField fieldToChange={"newFlightNum"} /> },

        newFlightDate: { label: "Nye flyvning dato",
                         element: <InfoDate fieldToChange="newFlightDate" /> },

        newFlightAirline: { label: "Nye flyvning flyselskab",
                            element: <InfoAirlineAutocomplete fieldToChange="newFlightAirline" /> },

        newFlightStats: { label: "Indhentet fly data til nye flyvning",
                          element: <InfoDialog fieldToChange="newFlightStats" Dialog={FlightStatsView} /> },

        endFlightNumPrefix: { label: "endFlightNumPrefix",
                              element: <InfoTextField fieldToChange={"endFlightNumPrefix"} /> },

        endFlightNum: { label: "endFlightNum",
                        element: <InfoTextField fieldToChange={"endFlightNum"} /> },

        endFlightDate: { label: "Endelige flyvning dato",
                         element: <InfoDate fieldToChange="endFlightDate" /> },

        endFlightStats: { label: "Indhentet fly data til slut flyvning",
                          element: <InfoDialog fieldToChange="endFlightStats" Dialog={FlightStatsView} /> },

        endFlightAirline: { label: "Endelige flyvning flyselskab",
                            element: <InfoAirlineAutocomplete fieldToChange="endFlightAirline" /> },

        afterSpecificFlightNumPrefix: { label: "afterSpecificFlightNumPrefix",
                              element: <InfoTextField fieldToChange={"afterSpecificFlightNumPrefix"} /> },

        afterSpecificFlightNum: { label: "afterSpecificFlightNum",
                        element: <InfoTextField fieldToChange={"afterSpecificFlightNum"} /> },

        afterSpecificFlightDate: { label: "afterSpecificFlightDate",
                         element: <InfoDate fieldToChange="afterSpecificFlightDate" /> },

        afterSpecificFlightStats: { label: "afterSpecificFlightStats",
                                    element: <InfoDialog fieldToChange="afterSpecificFlightStats" Dialog={FlightStatsView} /> },

        afterSpecificFlightAirline: { label: "afterSpecificFlightAirline",
                                      element: <InfoAirlineAutocomplete fieldToChange="afterSpecificFlightAirline" /> },

        finalFlightNumPrefix: { label: "finalFlightNumPrefix",
                                element: <InfoTextField fieldToChange={"finalFlightNumPrefix"} /> },

        finalFlightNum: { label: "finalFlightNum",
                        element: <InfoTextField fieldToChange={"finalFlightNum"} /> },

        finalFlightDate: { label: "finalFlightDate",
                         element: <InfoDate fieldToChange="finalFlightDate" /> },


        finalFlightStats: { label: "finalFlightStats",
                            element: <InfoDialog fieldToChange="finalFlightStats" Dialog={FlightStatsView} /> },

        finalFlightAirline: { label: "finalFlightAirline",
                              element: <InfoAirlineAutocomplete fieldToChange="finalFlightAirline" /> },

        wrongedDepAirport: { label: "Afgangs lufthavn (wrongedDepAirport)",
                             element: <InfoAirportAutocomplete fieldToChange="wrongedDepAirport" /> },

        wrongedArrAirport: { label: "Ankomst lufthavn (wrongedArrAirport)",
                             element: <InfoAirportAutocomplete fieldToChange="wrongedArrAirport" /> },

        stopOverAirports: { label: "Se mellemlandinger",
                            element: <InfoDialog fieldToChange="stopOverAirports" Dialog={StopOverAirportsView } /> },

        noDocsDate: { label: "Ca. ankomst dato til endelige ankomstlufthavn",
                      element: <InfoDate fieldToChange="noDocsDate" /> },

        email: { label: "email", 
                 element: <InfoTextField fieldToChange={"email"} /> },

        phoneNumber: { label: "Phone number", 
                       element: <InfoTextField fieldToChange={"phoneNumber"} /> },

        regNum: { label: "Regnr", 
                  element: <InfoTextField fieldToChange={"regNum"} /> },

        accountNum: { label: "Kontonr",
                      element: <InfoTextField fieldToChange={"accountNum"} /> },
        compensation: { label: "Kompensation",
                              element: <InfoTextField fieldToChange={"compensation"} /> },

        reimbursment: { label: "Refusion",
                                   element: <InfoTextField fieldToChange={"reimbursment"} /> },

        expense: { label: "Ekstra udgifter",
                   element: <InfoTextField fieldToChange={"expense"} /> },

        comment: { label: "Kommentar",
                   element: <InfoTextField fieldToChange={"comment"} /> },

        refund: { label: "Refunderet beløb",
                  element: <InfoTextField fieldToChange={"refund"} /> },

        bookingRef: { label: "Booking reference",
                      element: <InfoDialog fieldToChange="bookingRef" Dialog={BookingRefView} /> },

        // claimType: { label: "claimType",
                     // element: <InfoSelect fieldEnum={ClaimType} fieldToChange="claimType" /> },

        // distanceRange: { label: "distanceRange",
                     // element: <InfoSelect fieldEnum={DistanceRange} fieldToChange="distanceRange" /> },

        // distance: { label: "distance",
                  // element: <InfoTextField fieldToChange={"distance"} /> }, 
        }
}

const toRows = (): Array<ReactElement> => { 
    const elements = toReactElements()
    return Object.entries(elements).map(([key, value], i: number) => {
            return <>
                        <TableCell key={i + value.label}>
                            { value.label }
                        </TableCell>
                        <TableCell key={value.label + i}>
                            { value.element }
                        </TableCell>
                   </>
    })
}

const InfoTableBody = () => {
    const rows = toRows()
    return <Table>
             <TableBody>
               { rows.map((x: ReactElement, i: number) => <TableRow key={i}>{x}</TableRow> ) }
             </TableBody>
           </Table>
}

const InfoTextField = (props: { fieldToChange: string }) => {
    const dispatch = useAppDispatch()
    const row = useAppSelector(state => state.caseData.newRow)

    if (row === null)
        return <></>
    else
        return <TextField variant="standard" 
                   // @ts-ignore
                   defaultValue={row[props.fieldToChange]} 
                   onChange={(e: any) => dispatch(setNewRowField({ fieldToChange: props.fieldToChange, value: e.target.value }))} 
    />
}

const InfoSelect = (props: { fieldEnum: any, fieldToChange: string }) => {
    const dispatch = useAppDispatch()
    const row = useAppSelector(state => state.caseData.newRow)

    // Find a way to not do this retarded 500 thing
    return <FormControl size="small">
             <Select
                // @ts-ignore
                value={row[props.fieldToChange] || 500}
                onChange={(e: any) => dispatch(setNewRowField({ fieldToChange: props.fieldToChange, value: e.target.value === 500 ? null : e.target.value }))}
             >
             {
                // @ts-ignore
                Object.values(props.fieldEnum).map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
             }
               <MenuItem value={500}> - </MenuItem>
             </Select>
           </FormControl>
}

const InfoAirlineAutocomplete = (props: { fieldToChange: string }) => {
    const dispatch = useAppDispatch()
    const row = useAppSelector(state => state.caseData.newRow)
    const [airlineAutocomp, setAirlineAutocomp] = 
      useState<Array<any>>([])

    const handleAutocompAirlineReq = (f: HandleAirlineAutcomFunc) => async (e: object) => {
        // @ts-ignore
        const data = await searchAirlines(e.target.value)
        if (data.ok)
          f(data.val)
    }

    return <Autocomplete
             options={airlineAutocomp}
             noOptionsText={"Søg efter et flyselskab"}
             // data-testid="specificFlightAirline-autocomplete"
             fullWidth
             // loading={true}
             onChange={(e: any, value: any) => dispatch(setNewRowField({ fieldToChange: props.fieldToChange, value: value.id }))}
             onInputChange={handleAutocompAirlineReq(setAirlineAutocomp)}
             getOptionLabel={(option) => `${option.name} (${option.iata})`}
             renderInput={(params) => <TextField {...params}
                                         // @ts-ignore
                                         label={row[props.fieldToChange]}
                                         size="small"
                                         variant="outlined"
                         />}
           />
}

const InfoAirportAutocomplete = (props: { fieldToChange: string }) => {
    const dispatch = useAppDispatch()
    const row = useAppSelector(state => state.caseData.newRow)

    const [airportAutocomp, setAirportAutocomp] = 
      useState<Array<AirportAutocompleteOption>>([])

    const handleAutocompAirportsReq = (f: HandleAirportAutcomFunc) => async (e: object) => {
        // @ts-ignore
        const data = await searchAirports(e.target.value)
        if (data.ok)
            f(data.val)
    }

    return <Autocomplete
      options={airportAutocomp}
      noOptionsText={"Søg efter en lufthavn"}
      // data-testid="wrongedArrAirport-autocomplete"
      fullWidth
      // loading={true}
      onChange={(e: any, value: any) => dispatch(setNewRowField({ fieldToChange: props.fieldToChange, value: value.iata }))}
      onInputChange={handleAutocompAirportsReq(setAirportAutocomp)}
      getOptionLabel={(option: any) => `${option.name} (${option.iata})`}
      renderInput={(params) => <TextField {...params}
                                  // @ts-ignore
                                  label={row[props.fieldToChange]}
                                  size="small"
                                  variant="outlined"
                  />}
    />
}

// FIXME: I think the 'id' field is unneccessary an can be pulled inside the compoents themselves
type DialogView = React.FunctionComponent<{ id: any, open: boolean, setOpen: (id: any) => void }>
const InfoDialog = (props: { fieldToChange: string, Dialog: DialogView }) => {
    // const dispatch = useAppDispatch()
    const row = useAppSelector(state => state.caseData.newRow)
    
    const [flightDialogOpen, setFlightDialogOpen] = useState<boolean>(false)

    const { Dialog } = props
    
    return <>
             <Button variant="contained" 
                     color="primary" 
                     // onClick={() => {setFlightStatsDialog(props.row.specificFlightStats)}}>
                     onClick={() => { setFlightDialogOpen(true) }}>
                Se info
             </Button>
             <Dialog 
                // @ts-ignore
                id={row[props.fieldToChange]} 
                open={flightDialogOpen} 
                setOpen={setFlightDialogOpen}/>
           </>
}

const InfoDate = (props: { fieldToChange: string }) => {
    const dispatch = useAppDispatch()
    const row = useAppSelector(state => state.caseData.newRow)

    return <LocalizationProvider 
             dateAdapter={AdapterDateFns}
             locale={da}
             localeText={{ cancelButtonLabel: "annuller", previousMonth: "Forrige måned", nextMonth: "Næste måned" }}
           >
             <MobileDatePicker
               label="Dato"
               inputFormat="dd/MM/yy"
               disableFuture
               renderInput={(params) => <TextField size="small" fullWidth {...params} />}
               // @ts-ignore
               value={row[props.fieldToChange]}
               onChange={(date) => dispatch(setNewRowField({ fieldToChange: props.fieldToChange, value: new Date(date) }))}
             />
           </LocalizationProvider>
}

const InfoTypography = (props: { fieldToChange: string }) => {
    const row = useAppSelector(state => state.caseData.newRow)
    // @ts-ignore
    return <Typography>{row[props.fieldToChange]}</Typography>
}

export { InfoTableBody }
